import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";
import TablePagination from "@mui/material/TablePagination";
import { CSVLink } from "react-csv";

const CartLogs = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [carts, setCarts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedCartId, setExpandedCartId] = useState(null); // Track expanded row
  const [cartDetails, setCartDetails] = useState({}); // Cache cart details to avoid re-fetching
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // search
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  useEffect(() => {
    const fetchCarts = async () => {
      try {
        const cartsCollectionRef = collection(db, `${collectionPath}Carts/CartsList/DataBase`);
        const querySnapshot = await getDocs(cartsCollectionRef);

        const cartsArray = await Promise.all(
          querySnapshot.docs.map(async (docSnap) => {
            const data = docSnap.data();

            // Fetch user verification status
            const userDocRef = doc(db, `Users/UsersList/DataBase/${data.user_id}`);
            const userDoc = await getDoc(userDocRef);
            const isAnonymous = userDoc.exists() ? userDoc.data().isAnonymous : false;

            return {
              id: docSnap.id,
              ...data,
              isAnonymous, // Include isAnonymous status
            };
          })
        );

        // Sort carts by date
        cartsArray.sort((a, b) => {
          const timeA = getFirebaseDate(a.date)?.getTime();
          const timeB = getFirebaseDate(b.date)?.getTime();
          if (!timeA) return 1;
          if (!timeB) return -1;
          return timeB - timeA; // Descending order
        });

        setCarts([...cartsArray]);
      } catch (error) {
        console.error("Error fetching carts:", error);
        toast.error("Error fetching carts");
      } finally {
        setLoading(false);
      }
    };

    fetchCarts();
  }, [db, collectionPath]);

  const getFirebaseDate = (firebaseTimestamp) => {
    if (firebaseTimestamp?.seconds) {
      return new Date(firebaseTimestamp.seconds * 1000); // Convert seconds to milliseconds
    }
    return null; // Handle missing or invalid timestamps
  };

  const formatShortTime = (time) => {
    if (time) {
      const date = new Date(time); // Ensure the input is a Date object
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${month}.${day}.${year} ${hours}:${minutes}`;
    }
    return "N/A";
  };

  const checkOrdersStatus = async (cart, db) => {
    try {
      if (cart?.stripe_webhook_data?.status !== "complete") {
        return "N/A"; // Skip verification if webhook isn't complete
      }

      const items = cart.items || []; // Assuming `items` is the collection in the cart
      const expectedOrderIds = items.map(
        (item) => `${item.seller_id}_${cart.id}` // Generate expected order ID
      );

      const ordersCollectionRef = collection(db, "Orders/OrdersList/DataBase");
      const ordersSnapshot = await getDocs(ordersCollectionRef);

      const existingOrderIds = ordersSnapshot.docs.map((doc) => doc.id); // Collect existing order IDs

      // Check if all expected orders exist
      const allOrdersExist = expectedOrderIds.every((orderId) => existingOrderIds.includes(orderId));

      return allOrdersExist ? "OK" : "ERROR"; // Return OK if all exist, otherwise ERROR
    } catch (error) {
      console.error("Error verifying orders:", error);
      return "ERROR"; // Fallback to ERROR if something goes wrong
    }
  };

  const fetchCartDetails = async (cartId) => {
    if (cartDetails[cartId]) {
      return cartDetails[cartId]; // Return cached details if already fetched
    }

    try {
      const itemsCollectionRef = collection(db, `${collectionPath}Carts/CartsList/DataBase/${cartId}/Items`);
      const itemsSnapshot = await getDocs(itemsCollectionRef);

      const items = itemsSnapshot.docs.map((doc) => {
        const data = doc.data();
        // Ensure all prices are rounded and formatted to two decimal places
        return {
          ...data,
          final_price: parseFloat(data.final_price.toFixed(2)), // Ensure proper format
        };
      });

      setCartDetails((prevDetails) => ({ ...prevDetails, [cartId]: items }));

      return items;
    } catch (error) {
      console.error("Error fetching cart details:", error);
      return [];
    }
  };

  const toggleCartDetails = async (cartId) => {
    if (expandedCartId === cartId) {
      setExpandedCartId(null); // Collapse if already expanded
    } else {
      await fetchCartDetails(cartId);
      setExpandedCartId(cartId); // Expand the clicked cart
    }
  };

  // Search
  const filteredCarts = carts.filter((cart) => {
    const address = cart?.shipmentAddress?.address?.toLowerCase() || "";
    const city = cart?.shipmentAddress?.city?.toLowerCase() || "";
    const email = cart?.shipmentAddress?.email?.toLowerCase() || "";
    const postalCode = cart?.shipmentAddress?.postalCode?.toLowerCase() || "";
    const state = cart?.shipmentAddress?.state?.toLowerCase() || "";

    return (
      address.includes(searchTerm.toLowerCase()) ||
      city.includes(searchTerm.toLowerCase()) ||
      email.includes(searchTerm.toLowerCase()) ||
      postalCode.includes(searchTerm.toLowerCase()) ||
      state.includes(searchTerm.toLowerCase())
    );
  });

  // pagination
  const paginatedCarts = filteredCarts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Generates CSV data for cart logs.
   * @returns {Object} - Contains headers and data for CSV.
   */
  const generateCsvData = () => {
    const headers = [
      { label: "Cart ID", key: "id" },
      { label: "User ID", key: "user_id" },
      { label: "CSS ID", key: "cart_sooperstock_ID" },
      { label: "Status", key: "status" },
      { label: "Created", key: "created" },
      { label: "Cart Page Opened", key: "opening_cart_page" },
      { label: "Checkout Page Opened", key: "opening_checkout_page" },
      { label: "Total Amount", key: "finalAmount" },
      { label: "Stripe Status", key: "stripeStatus" },
      { label: "Wallet Deduction", key: "walletDeduction" },
      { label: "Is Anonymous", key: "isAnonymous" },
    ];

    const data = filteredCarts.map((cart) => ({
      id: cart.id,
      user_id: cart.user_id || "N/A",
      cart_sooperstock_ID: cart.cart_sooperstock_ID || "N/A",
      status: cart.status || "N/A",
      created: formatShortTime(getFirebaseDate(cart.date)),
      opening_cart_page: formatShortTime(getFirebaseDate(cart.opening_cart_page)),
      opening_checkout_page: formatShortTime(getFirebaseDate(cart.opening_checkout_page)),
      finalAmount: cart.finalAmount ? `$${cart.finalAmount.toFixed(2)}` : "-",
      stripeStatus: cart.stripePaymentData?.status
        ? cart.stripePaymentData.status
        : cart.stripe_webhook_data?.status
        ? `${cart.stripe_webhook_data.status} ($${(cart.paid_amount_total / 100).toFixed(2)})`
        : "not used",
      walletDeduction: cart.walletDeduction ? `$${cart.walletDeduction}` : "-",
      isAnonymous: cart.isAnonymous ? "Yes" : "No",
    }));

    return { headers, data };
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <div style={{ display: "flex", justifyContent: "space-between", alignContent: "flex-end", marginBottom: "10px" }}>
        <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Carts Logs</h2>
        <div style={{ display: "flex", height: 40, marginTop: 13 }}>
          <CSVLink
            {...generateCsvData()}
            filename="cart_logs.csv"
            className="btn btn-primary"
            target="_blank"
            style={{
              padding: "10px 20px",
              color: "black",
              textDecoration: "none",
              borderRadius: "5px",
              border: "none",
              border: "1px solid #ccc",
              cursor: "pointer",
              fontSize: "14px",
              haight: "40px",
            }}
          >
            Download Carts as CSV
          </CSVLink>
        </div>
      </div>
      {loading ? (
        <p>Loading carts...</p>
      ) : (
        <div>
          <div style={{ display: "flex", padding: "0px 0 10px 0" }}>
            <input
              type="text"
              placeholder="Search by Address, City, Email, Postal Code, or State"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
            <button
              type="button"
              onClick={() => setSearchTerm("")}
              style={{
                padding: "0px 20px",
                marginLeft: 10,
                backgroundColor: "gray",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "18px",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              &times;
            </button>
          </div>
          <table style={styles.table}>
            <thead>
              <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
                <th style={styles.th}>Cart ID</th>
                <th style={styles.th}>User ID</th>
                <th style={styles.th}>CSS ID</th>
                <th style={styles.th}>Status</th>
                <th style={styles.th}>Created</th>
                <th style={styles.th}>Cart P.</th>
                <th style={styles.th}>Checkout P.</th>
                <th style={styles.th}>Total Amount</th>
                <th style={styles.th}>Stripe</th>
                <th style={styles.th}>Wallet</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCarts.map((cart) => {
                const isExpanded = expandedCartId === cart.id;

                return (
                  <React.Fragment key={cart.id}>
                    <tr style={styles.row} onClick={() => toggleCartDetails(cart.id)}>
                      <td style={{ ...styles.td, fontSize: 10 }}>{cart.id}</td>
                      <td style={{ ...styles.td, fontSize: 10 }}>
                        {cart.user_id || "N/A"}
                        {cart.isAnonymous ? (
                          <></>
                        ) : (
                          <img
                            src="/images/verified.png"
                            style={{
                              position: "absolute",
                              marginLeft: 5,
                              marginTop: -2,
                              height: 15,
                              width: 15,
                            }}
                          />
                        )}
                      </td>
                      <td style={styles.td}>{cart.cart_sooperstock_ID || "N/A"}</td>
                      <td style={styles.td}>
                        {cart.status == "PAID" ? (
                          <div style={{ ...styles.label, background: "lightgreen" }}>{cart.status || "N/A"}</div>
                        ) : (
                          <div style={{ ...styles.label, backgroundColor: "silver" }}>{cart.status || "N/A"}</div>
                        )}
                      </td>
                      <td style={{ ...styles.td, fontSize: 12 }}>{formatShortTime(getFirebaseDate(cart.date))}</td>
                      <td style={{ ...styles.td, fontSize: 12 }}>{formatShortTime(getFirebaseDate(cart.opening_cart_page))}</td>
                      <td style={{ ...styles.td, fontSize: 12 }}>{formatShortTime(getFirebaseDate(cart.opening_checkout_page))}</td>
                      <td style={{ ...styles.td, fontSize: 12, fontWeight: "600" }}>
                        {" "}
                        {cart?.finalAmount && cart?.finalAmount > 0 ? (
                          <div style={{ ...styles.label, background: "gold" }}>{"$" + cart?.finalAmount || "N/A"}</div>
                        ) : (
                          <div style={{}}>-</div>
                        )}
                      </td>
                      <td style={{ ...styles.td }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {cart.stripePaymentData?.status && <div style={{ ...styles.label }}>{cart.stripePaymentData?.status || "N/A"}</div>}
                          {cart.stripe_webhook_data?.status && (
                            <div style={{ ...styles.label }}>
                              {cart.stripe_webhook_data?.status + "($" + (cart.paid_amount_total / 100).toFixed(2) + ")" || "N/A"}
                            </div>
                          )}
                          {!cart.stripePaymentData?.status && !cart.stripe_webhook_data?.status && (
                            <div style={{ ...styles.label, background: "silver", color: "gray" }}>not used</div>
                          )}
                        </div>
                      </td>
                      <td style={{ ...styles.td, alignItems: "center" }}>
                        {cart.walletDeduction ? (
                          <div style={{ ...styles.label, background: "lightgreen", fontSize: 12, textAlign: "center" }}>${cart.walletDeduction}</div>
                        ) : (
                          <div style={{ textAlign: "center" }}>-</div>
                        )}
                      </td>
                    </tr>
                    {isExpanded && (
                      <tr>
                        <td colSpan="11" style={styles.expandedRow}>
                          <h4>Buyer Details</h4>
                          <p>
                            <strong>User ID:</strong> {cart.user_id || "N/A"}
                          </p>
                          {cart.shipmentAddress ? (
                            <>
                              <p>
                                <strong>Address:</strong> {cart.shipmentAddress.address || "N/A"}
                              </p>
                              <p>
                                <strong>Address Name:</strong> {cart.shipmentAddress.address_name || "N/A"}
                              </p>
                              <p>
                                <strong>Email:</strong> {cart.shipmentAddress.email || "N/A"}
                              </p>
                            </>
                          ) : (
                            <p>User didn't set up shipment details yet.</p>
                          )}
                          <p>
                            <strong>Current Cart Status:</strong> {cart.status || "N/A"}
                          </p>

                          <h4>Cart Details</h4>
                          <ul style={styles.detailsList}>
                            {cartDetails[cart.id]?.map((item, index) => (
                              <li key={index}>
                                {item.product_name} - ${item.final_price.toFixed(2)} x {item.quantity} = ${(item.final_price * item.quantity).toFixed(2)}
                              </li>
                            ))}
                            {cart.shippingCost && (
                              <li>
                                Shipping - ${cart.shippingCost.toFixed(2)} x 1 = ${cart.shippingCost.toFixed(2)}
                              </li>
                            )}
                            <li>
                              <strong>
                                Total: $
                                {(cartDetails[cart.id]?.reduce((acc, item) => acc + item.final_price * item.quantity, 0) + (cart.shippingCost || 0)).toFixed(2)}
                              </strong>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div style={{ background: "#fafafa", border: "1px solid silver", borderTop: "0px" }}>
        <TablePagination
          component="div"
          count={carts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10, 25]}
        />
      </div>
    </div>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  row: {
    cursor: "pointer",
  },
  expandedRow: {
    backgroundColor: "#f9f9f9",
    padding: "10px",
    border: "1px solid #d1d0d0",
  },
  detailsList: {
    listStyleType: "none",
    padding: 0,
  },
  label: {
    padding: "5px 10px",
    borderRadius: "25px",
    color: "white",
    fontSize: "11px",
    color: "black",
    fontWeight: 600,
    backgroundColor: "lightblue",
    display: "inline-block",
    marginRight: "5px",
  },
};

export default CartLogs;
