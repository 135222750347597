// FeaturedPage.js
import React, { useContext, useEffect, useState } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDocs, collection, doc, setDoc, updateDoc, deleteDoc, writeBatch, addDoc, Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Button, TextField, Typography, Box, Switch } from "@mui/material";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

/**
 * SortableTableRow Component
 * Integrates @dnd-kit sortable functionality with MUI's TableRow.
 */
const SortableTableRow = ({ id, index, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: isDragging ? "#f0f0f0" : "inherit",
    cursor: "grab",
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </TableRow>
  );
};

/**
 * FeaturedPage Component
 * Displays and manages featured products with drag-and-drop reordering.
 */
const FeaturedPage = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const storage = getStorage();

  // Determine the collection path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // State variables
  const [allProducts, setAllProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customName, setCustomName] = useState("");
  const [customURL, setCustomURL] = useState("");

  // Initialize sensors for @dnd-kit
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Drag activation after moving 5 pixels
      },
    })
  );

  /**
   * Fetch products and featured products from Firestore on component mount
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch all products
        const listingsCollectionRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
        const listingsSnapshot = await getDocs(listingsCollectionRef);
        const productsData = listingsSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));

        // Fetch featured products ordered by 'sorting'
        const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
        const featuredSnapshot = await getDocs(featuredCollectionRef);
        const featuredData = featuredSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));

        // Sort featured products by 'sorting' field
        featuredData.sort((a, b) => a.sorting - b.sorting);

        // Update state
        setFeaturedProducts(featuredData);
        setAllProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [db, collectionPath]);

  /**
   * Handle image upload for a featured product
   * @param {string} productId - ID of the product
   * @param {File} file - Image file to upload
   */
  const handleUploadImage = async (productId, file) => {
    if (!file) return;

    try {
      const storageRef = ref(storage, `Featured/${productId}`); // Define storage path
      await uploadBytes(storageRef, file); // Upload image
      const url = await getDownloadURL(storageRef); // Get download URL

      // Update Firestore with new image URL
      const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId);
      await updateDoc(docRef, { image_url: url });

      // Update state to reflect the new image
      setFeaturedProducts((prev) => prev.map((product) => (product.id === productId ? { ...product, image_url: url } : product)));

      console.log("Image successfully uploaded and updated");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  /**
   * Toggle the 'enabled' state of a featured product
   * @param {string} productId - ID of the product
   * @param {boolean} enabled - New enabled state
   */
  const handleToggleEnabled = async (productId, enabled) => {
    try {
      const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId);
      await updateDoc(docRef, { enabled });

      // Update state
      setFeaturedProducts((prev) => prev.map((product) => (product.id === productId ? { ...product, enabled } : product)));
    } catch (error) {
      console.error("Error toggling enabled state:", error);
    }
  };

  /**
   * Add a product to the featured list
   * @param {Object} product - Product object to add
   */
  const handleAddToFeatured = async (product) => {
    if (featuredProducts.length >= 5) return; // Limit to 5 featured products

    const position = featuredProducts.length + 1;
    const newFeaturedProduct = {
      id: product.id,
      product_name: product.product_name,
      date_created: Timestamp.fromDate(new Date()),
      banner_target_url: `/Listing/${product.seller_product_id}`, // Auto-generated URL
      featured_position: position,
      enabled: false,
    };

    try {
      const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, product.id);
      await setDoc(docRef, newFeaturedProduct, { merge: true }); // Add to Firestore
      setFeaturedProducts([...featuredProducts, newFeaturedProduct]); // Update state
      console.log("Product added to featured list");
    } catch (error) {
      console.error("Error adding to featured:", error);
    }
  };

  /**
   * Add a custom featured product
   */
  const handleAddCustomFeatured = async () => {
    if (featuredProducts.length >= 5) return;
    if (!customName || !customURL) {
      alert("Please provide both name and URL.");
      return;
    }

    const position = featuredProducts.length + 1;
    const newFeaturedProduct = {
      product_name: customName,
      date_created: Timestamp.fromDate(new Date()),
      banner_target_url: customURL,
      featured_position: position,
      enabled: false,
      isCustom: true, // Flag to identify custom entries
    };

    try {
      const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
      const docRef = await addDoc(featuredCollectionRef, newFeaturedProduct); // Add to Firestore
      const addedProduct = { id: docRef.id, ...newFeaturedProduct };
      setFeaturedProducts([...featuredProducts, addedProduct]); // Update state
      setCustomName("");
      setCustomURL("");
      console.log("Custom product added to featured list");
    } catch (error) {
      console.error("Error adding custom featured product:", error);
    }
  };

  /**
   * Remove a product from the featured list
   * @param {string} productId - ID of the product to remove
   */
  const handleRemoveFromFeatured = async (productId) => {
    try {
      await deleteDoc(doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, productId)); // Remove from Firestore
      setFeaturedProducts((prev) => prev.filter((product) => product.id !== productId)); // Update state
      console.log("Product removed from featured list");
    } catch (error) {
      console.error("Error removing product from featured:", error);
    }
  };

  /**
   * Handle the end of a drag event
   * @param {Object} event - Drag event object
   */
  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = featuredProducts.findIndex((item) => item.id === active.id);
      const newIndex = featuredProducts.findIndex((item) => item.id === over.id);

      // Reorder the featuredProducts array locally
      const reordered = arrayMove(featuredProducts, oldIndex, newIndex);
      setFeaturedProducts(reordered);

      try {
        // Initialize a batch
        const batch = writeBatch(db);

        // Iterate over the reordered list and update the 'sorting' field
        reordered.forEach((product, index) => {
          const docRef = doc(db, `${collectionPath}Listings/FeaturedList/DataBase`, product.id);
          batch.update(docRef, { sorting: index + 1 }); // Assuming 'sorting' is the field name
        });

        // Commit the batch
        await batch.commit();
        console.log("Sorting order successfully updated in Firestore.");
      } catch (error) {
        console.error("Error updating sorting order:", error);
      }
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h4">Featured Products</Typography>

      {/* Featured Products Table with Drag-and-Drop */}
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={featuredProducts.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Banner Name</TableCell>
                  <TableCell>Banner Target URL</TableCell> {/* New Column */}
                  <TableCell>Enabled</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featuredProducts.map((product, index) => (
                  <SortableTableRow key={product.id} id={product.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{product.product_name}</TableCell>
                    <TableCell>{product.banner_target_url}</TableCell> {/* New Cell */}
                    <TableCell>
                      <Switch checked={product.enabled} onChange={(e) => handleToggleEnabled(product.id, e.target.checked)} />
                    </TableCell>
                    <TableCell>
                      {product.image_url ? (
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 100,
                              height: 50,
                              overflow: "hidden",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <img src={product.image_url} alt={product.product_name} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                          </Box>
                          <input type="file" onChange={(e) => handleUploadImage(product.id, e.target.files[0])} accept="image/*" />
                        </Box>
                      ) : (
                        <input type="file" onChange={(e) => handleUploadImage(product.id, e.target.files[0])} accept="image/*" />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="outlined" color="error" onClick={() => handleRemoveFromFeatured(product.id)}>
                        Remove
                      </Button>
                    </TableCell>
                  </SortableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>

      {/* Add Custom Featured Product Form */}
      <Box mt={4} mb={2}>
        <Typography variant="h6">Add Custom Featured Product</Typography>
        <Box display="flex" alignItems="center" gap={2} mt={1} style={{}}>
          <TextField label="Banner Name" value={customName} onChange={(e) => setCustomName(e.target.value)} variant="outlined" style={{ flex: "1" }} />
          <TextField label="Custom URL" value={customURL} onChange={(e) => setCustomURL(e.target.value)} variant="outlined" style={{ flex: "1" }} />
          <Button variant="outlined" color="primary" onClick={handleAddCustomFeatured} style={{ height: "55px" }}>
            ADD
          </Button>
        </Box>
      </Box>

      {/* Spacer */}
      <Box height="50px" />

      {/* Search Section */}
      <Typography variant="h6">Add Product as Featured</Typography>
      <TextField label="Search Products" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} fullWidth sx={{ my: 2 }} />

      {/* All Products Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {allProducts
              .filter((prod) => prod.product_name.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.product_name}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleAddToFeatured(product)} disabled={featuredProducts.length >= 5}>
                      Add to Featured
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FeaturedPage;
